/* Universal Padding and Margin Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --box-shadow: 9px 9px 30px rgba(0, 0, 0, 0.3);
  --hover-shadow: 0 9px 15px rgba(0, 0, 0, 0.1);
  --wave-duration: 2s;
}

.categories {
  padding: 70px 0;
}

.box {
  width: 100%;
  height: 300px; /* Ensure the cards have the same height */
  margin-bottom: 30px;
  position: relative;
  padding: 3px;
  overflow: hidden;
  border: 0 solid #000;
  box-shadow: var(--box-shadow);
  transition: all 0.3s ease-in-out;
}

.box img {
  width: 100%;
  height: 100%; /* Ensure the images fill the card */
  opacity: 0.9;
  transition: opacity 0.35s, transform 0.35s;
  animation: wave var(--wave-duration) infinite ease-in-out;
}

.details {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  opacity: 0;
  transition: opacity 0.35s;
}

.box:hover .details {
  opacity: 1;
}

.view-btn {
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.box:hover img {
  opacity: 0.6;
  transform: translate3d(0, 0, 0);
}

@keyframes wave {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
}

.close {
  position: absolute;
  top: 20px;
  right: 30px;
  color: #fff;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
}

.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  animation: zoom 0.6s;
  position: relative;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #fff;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 5px;
  border-radius: 50%;
  box-shadow: var(--box-shadow);
}

@keyframes zoom {
  from {transform: scale(0)} 
  to {transform: scale(1)}
}

@media screen and (max-width: 700px) {
  .modal-content {
    width: 100%;
  }
}
