@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 111%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 111%;
  }

  #portfolio {
    width: 110%;
  }
}

/* Add this to your App.css or a separate CSS file */

.fade-enter {
  opacity: 0;
  transform: translateY(20px); /* Move down when entering */
}

.fade-enter-active {
  opacity: 1;
  transform: translateY(0); /* Move to original position */
  transition: opacity 300ms ease, transform 300ms ease; /* Adjust timing */
}

.fade-exit {
  opacity: 1;
  transform: translateY(0); /* Stay in place when exiting */
}

.fade-exit-active {
  opacity: 0;
  transform: translateY(-20px); /* Move up when exiting */
  transition: opacity 300ms ease, transform 300ms ease; /* Adjust timing */
}
