/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Playfair+Display:wght@700&display=swap');

/* General styles */
body {
  font-family: 'Roboto', sans-serif;
}

.products-title {
  font-family: 'Playfair Display', serif;
  text-align: center;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 40px;
  color: #333;
}

.products-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.product-card {
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  transition: all 0.3s ease;
  width: 300px;
}

.product-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.product-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 20px;
}

.product-name {
  font-size: 22px;
  margin-bottom: 10px;
  font-family: 'Playfair Display', serif;
}

.product-description {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
  font-family: 'Roboto', sans-serif;
}

.product-price {
  font-size: 18px;
  font-weight: 700;
  color: #2E8B57;
}
