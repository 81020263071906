#faq {
  font-family: "Arial", sans-serif;
  padding: 20px;
  max-width: 800px;
  margin-top: 100px !important;
  margin: auto;
  background: linear-gradient(
      135deg,
      rgba(240, 240, 240, 0.9),
      rgba(232, 232, 232, 0.9)
    ),
    url("/img/S6.png") no-repeat center center;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

#faq h2 {
  text-align: center;
  margin-bottom: 30px;
  padding-bottom: 10px;
  border-bottom: 3px solid #3498db; /* Blue accent */
  font-size: 2.5rem;
  color: #2c3e50;
  font-weight: bold;
  text-transform: uppercase;
}

.faq-category {
  margin-bottom: 40px;
}

.faq-category-title {
  font-size: 2rem;
  margin-bottom: 20px;
  font-weight: bold;
  color: #2c3e50;
  border-left: 5px solid #3498db; /* Accent color */
  padding-left: 10px;
  text-transform: uppercase;
}

.faq-item {
  margin-bottom: 15px;
  padding: 15px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease;
  border-left: 3px solid transparent;
}

.faq-item:hover {
  transform: translateY(-5px);
  border-left: 3px solid #3498db;
  background-color: #f9f9f9;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 1.3rem;
  font-weight: bold;
  color: #34495e;
  transition: color 0.3s ease;
}

.faq-question:hover {
  color: #3498db;
}

.faq-answer {
  margin-top: 10px;
  padding-left: 20px;
  font-size: 1.2rem;
  line-height: 1.6;
  color: #7f8c8d;
  transition: all 0.3s ease;
}

.faq-item.active .faq-answer {
  display: block; /* Show on active state */
}
.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease, padding 0.3s ease;
}

.faq-item .faq-answer {
  max-height: 500px; /* Adjust based on content */
  padding-top: 10px;
}

.faq-icon {
  font-size: 1.5rem;
  color: #3498db;
  transition: transform 0.3s ease;
}

.faq-item.active .faq-icon {
  transform: rotate(45deg); /* Rotates icon on active */
}

/* Add subtle animations */
.faq-item {
  animation: fadeInUp 0.5s ease both;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Add responsive design */
@media (max-width: 768px) {
  #faq {
    padding: 15px;
  }

  #faq h2 {
    font-size: 2rem;
  }

  .faq-category-title {
    font-size: 1.5rem;
  }

  .faq-question {
    font-size: 1.1rem;
  }

  .faq-answer {
    font-size: 1rem;
  }
}
