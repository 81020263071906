.main {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.slider-container {
  position: relative;
  height: 100%;
  background-color: #004d00;
}

.slide {
  position: relative;
}

.image {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4); /* Adjust opacity as needed */
  z-index: 1;
}

.image-data {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: white;
  text-align: left;
  z-index: 2;
  padding: 20px;
  font-family: 'Poppins', sans-serif;
  width: 100%;
  max-width: 1200px;
}

.left-content {
  flex: 1;
  text-align: left;
}

.right-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 5%; /* Adjust the space as needed */
}

.spacer {
  flex: 0 0 5%; /* Adjust space as needed */
}

.welcome-message h2 {
  margin: 0.5rem 0;
}

.text {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

h1 {
  font-size: 5rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

h3 {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

p {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
}

.button {
  background-color: #006600;
  color: white;
  padding: 1.5rem 3rem;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1.8rem;
  font-weight: 700;
}

.slick-prev,
.slick-next {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  transition: background-color 0.3s;
}

.slick-prev::before,
.slick-next::before {
  color: #5e9b50;
  font-size: 25px;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.slick-prev {
  left: 15px;
}

.slick-next {
  right: 15px;
}

.slick-prev:hover,
.slick-next:hover {
  background-color: rgba(255, 255, 255, 1);
}

.slick-dots li button:before {
  color: white;
  font-size: 12px;
}

.slick-dots li.slick-active button:before {
  color: #5e9b50;
}

@media (max-width: 1200px) {
  .image-data {
    flex-direction: column;
    align-items: flex-start;
  }

  .right-content {
    margin-left: 0;
  }

  .spacer {
    display: none;
  }

  .left-content,
  .right-content {
    width: 100%;
    margin-bottom: 2rem;
  }
}

@media (max-width: 768px) {
  .text {
    font-size: 2rem;
  }

  h1 {
    font-size: 3rem;
  }

  h3 {
    font-size: 2.5rem;
  }

  p {
    font-size: 1.5rem;
  }

  .button {
    padding: 1rem 2rem;
    font-size: 1.5rem;
  }

  .image-data {
    width: 90%;
    align-items: flex-start;
  }
}

@media (max-width: 480px) {
  .text {
    font-size: 1.8rem;
  }

  h1 {
    font-size: 2.5rem;
  }

  h3 {
    font-size: 2rem;
  }

  p {
    font-size: 1.2rem;
  }

  .button {
    padding: 0.8rem 1.5rem;
    font-size: 1.2rem;
  }

  .image-data {
    width: 95%;
  }
}

@media (max-height: 400px) and (orientation: landscape) {
  .main {
    height: auto;
  }

  .image {
    height: 60vh;
  }

  .image-data {
    top: 40%;
    transform: translateY(-40%);
  }

  .text,
  h1,
  h3,
  p,
  .button {
    font-size: 1.5rem;
  }

  .button {
    padding: 1rem 2rem;
  }
}
