/* General Styles */
.all-title-box {
  background: url('/img/cattle.jpg') no-repeat center center;
  background-size: cover;
  padding: 50px 0;
  text-align: center;
  color: #fff;
  font-family: 'Roboto', sans-serif;
}

.all-title-box h2 {
  font-size: 36px;
  font-weight: 700;
}

.breadcrumb {
  padding: 0;
  list-style: none;
  background: none;
  margin-bottom: 0;
  display: inline-block; /* Ensure breadcrumb is inline */
}

.breadcrumb-item {
  display: inline; /* Ensure items are inline */
  color: #fff; /* Ensure visibility */
}

.breadcrumb-item a {
  color: #fff; /* Ensure links are visible */
}

.breadcrumb-item.active {
  color: #e0e0e0; /* Slightly different color for active item */
}

.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  padding: 0 5px;
  color: #fff; /* Ensure separator is visible */
}

/* About Section */
.about-box-main {
  display: flex;
  align-items: center;
  padding: 50px 0;
  background: #ffffff;
}

.about-box-main .banner-frame {
  flex: 1;
  max-height: 400px;
  overflow: hidden;
}

.banner-frame img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about-box-main .noo-sh-title-top {
  flex: 1;
  font-family: 'Open Sans', sans-serif;
  font-size: 36px;
  font-weight: 700;
  color: #333;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-bottom: 3px solid #5cb85c;
  display: inline-block;
  padding-bottom: 5px;
}

.about-box-main p {
  flex: 1;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  line-height: 1.6;
  color: #666;
  margin-bottom: 20px;
  text-align: justify;
}

.about-box-main .btn {
  background: #5cb85c;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 30px;
  transition: all 0.3s;
}

.about-box-main .btn:hover {
  background: #4cae4c;
  color: #ffffff;
}

/* Service Section */
.service-block-inner {
  text-align: center;
  padding: 20px;
  border: 1px solid #f1f1f1;
  background: #f8f8f8;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.service-block-inner h3 {
  font-size: 22px;
  font-weight: 700;
  margin: 10px 0;
}

.service-block-inner p {
  font-size: 14px;
  line-height: 24px;
  color: #666;
}

.icon-color {
  color: #5e9b50;
  margin-bottom: 10px;
}

/* Team Section */
.hover-team {
  margin-bottom: 30px;
}

.our-team {
  position: relative;
  overflow: hidden;
  text-align: center;
  background: #ffffff;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.our-team img {
  width: 100%;
  height: auto;
}

.team-content {
  padding: 15px 10px;
}

.team-content h3 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 5px;
}

.team-content .post {
  display: block;
  font-size: 14px;
  color: #5e9b50;
  margin-bottom: 10px;
}

.social {
  padding: 0;
  margin: 0;
  list-style: none;
  display: inline-flex;
  justify-content: center;
}

.social li {
  margin: 0 5px;
}

.social li a {
  display: block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  background: #5e9b50;
  color: #ffffff;
  border-radius: 50%;
  text-align: center;
  transition: all 0.3s;
}

.social li a:hover {
  background: #5e9b50;
}

/* Instagram Feed */
.instagram-box {
  padding: 50px 0;
  background: #f8f8f8;
}

.instagram-box .ins-inner-box {
  position: relative;
  overflow: hidden;
}

.instagram-box img {
  width: 100%;
  height: auto;
  transition: all 0.3s;
}

.instagram-box .hov-in {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.3s;
}

.instagram-box:hover .hov-in {
  opacity: 1;
}

.instagram-box:hover img {
  transform: scale(1.1);
}

.instagram-box .hov-in a {
  display: inline-block;
  background: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  padding: 10px;
  border-radius: 50%;
}

/* Responsive Design */
@media (max-width: 768px) {
  .all-title-box {
    padding: 30px 0;
  }
  .about-box-main {
    flex-direction: column;
  }
  .service-block-inner {
    margin-bottom: 20px;
  }
  .social li a {
    width: 25px;
    height: 25px;
    line-height: 25px;
  }
}
/* Responsive Design */
@media (max-width: 768px) {
  .all-title-box {
    padding: 30px 0;
  }
  .about-box-main {
    flex-direction: column;
  }
  .about-box-main .banner-frame {
    margin-bottom: 20px; /* Adding space between image and text */
  }
  .breadcrumb {
    font-size: 14px; /* Smaller font for breadcrumbs on small screens */
  }
  .service-block-inner {
    margin-bottom: 20px;
  }
  .social li a {
    width: 25px;
    height: 25px;
    line-height: 25px;
  }
}

@media (max-width: 576px) {
  .all-title-box h2 {
    font-size: 28px;
  }
  .about-box-main .noo-sh-title-top {
    font-size: 24px;
  }
  .about-box-main p {
    font-size: 16px;
  }
  .service-block-inner h3 {
    font-size: 18px;
  }
  .service-block-inner p {
    font-size: 12px;
  }
}
