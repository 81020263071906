/* Testimonials Section */
#testimonials {
  padding: 100px 0;
  background:  white;
}
.text-center h2{
  color: black !important;
}
#testimonials i {
  color: #e6e6e6;
  font-size: 32px;
  margin-bottom: 20px;
}

.testimonial {
  position: relative;
  padding: 20px;
}

.testimonial-image {
  float: left;
  margin-right: 15px;
}

.testimonial-image,
.testimonial-image img {
  display: block;
  width: 64px;
  height: 64px;
  border-radius: 50%;
}

.testimonial-content {
  position: relative;
  overflow: hidden;
}

.testimonial-content p {
  margin-bottom: 0;
  font-size: 14px;
  font-style: italic;
  color: black; /* Ensure text is black */
}

.testimonial-meta {
  margin-top: 10px;
  font-size: 15px;
  font-weight: 600;
  color: black; /* Ensure text is black */
}

.section-title h2 {
  color: black; /* Ensure title is black */
}
