.footer {
  background: #274135; /* Dark green background */
  color: white;
  padding: 40px 20px;
}

.footer .container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.footer-content {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
}

.footer-section {
  flex: 1;
  min-width: 200px; /* Ensures sections don't shrink too much */
}

.footer-section h4 {
  font-size: 18px;
  margin-bottom: 10px;
}

.footer-section p, .footer-section ul {
  margin: 0 0 10px;
  font-size: 14px;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 5px;
}

.footer-section ul li a {
  color: white;
  text-decoration: none;
}

.footer-section ul li a:hover {
  text-decoration: underline;
}

.social-icons {
  display: flex;
  gap: 10px;
}

.social-icons a {
  color: white;
  font-size: 18px;
  display: inline-block;
}

.footer-bottom {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding-top: 10px;
  width: 100%; /* Ensure it takes full width */
}
