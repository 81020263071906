/* Ensure the entire background is consistent */
body, html {
  background-color: #f6f6f6;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: 'Roboto', sans-serif; /* Applying Roboto font */
}

/* Values Section Container */
.values-section {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #f6f6f6; /* Consistent background color */
  text-align: center;
}

.values-header h1 {
  font-family: "Raleway", sans-serif;
  font-size: 36px;
  color: #333;
  margin-bottom: 10px;
}

.values-header p {
  font-size: 18px;
  color: #777;
  margin-bottom: 20px;
}

.values-content {
  display: flex;
  align-items: stretch; /* Ensure the image matches the height of the cards */
  gap: 40px;
  flex-direction: row; /* Image and cards side by side */
}

/* Image Styling */
.values-image {
  flex: 1; /* Allows image to take equal space as container */
}

.values-image img {
  width: 100%;
  height: 100%; /* Match image height to container */
  object-fit: cover; /* Ensures the image covers the container without distortion */
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* Values Container */
.values-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns */
  grid-gap: 20px; /* Space between the cards */
  max-width: 700px;
  flex: 1; /* Take up equal space with image */
}

/* Individual Cards */
.value-card {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content horizontally */
  justify-content: center; /* Center content vertically */
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, background-color 0.3s;
  overflow: hidden;
  text-align: center;
}

.value-card:hover {
  transform: translateY(-10px);
  background-color: #e6ffe6;
}

.value-card-icon {
  font-size: 3em;
  margin-bottom: 10px;
  color: #2ecc71; /* Green color for icons */
}

.value-card-content h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #333;
}

.value-card-content p {
  font-size: 1em;
  color: #777;
}

.description-hover {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  background-color: rgba(0, 128, 0, 0.7); /* Greenish background */
  color: #fff;
  opacity: 0;
  transform: translateY(100%);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.value-card:hover .description-hover {
  opacity: 1;
  transform: translateY(0);
}

/* Responsive Design */
@media (max-width: 768px) {
  .values-content {
    flex-direction: column; /* Stack image and cards vertically */
    align-items: center;
  }

  .values-image {
    width: 100%;
    height: auto;
  }

  .values-container {
    grid-template-columns: 1fr; /* Single column on smaller screens */
    gap: 20px; /* Ensure proper spacing in single-column view */
  }

  .value-card {
    width: 100%;
  }
}
