
.contact-us-container {
  display: flex;
  max-width: 1000px;
  margin: auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-top: 80px;
  margin-bottom: 50px;
}

.contact-info {
  flex: 1;
  position: relative;
}
.contact-form input::placeholder,
.contact-form textarea::placeholder {
  color: #aaa; /* Lighter color for the placeholder text */
  opacity: 1;  /* Ensure full opacity */
}


.background-image {
  background-image: url('https://wallpaperaccess.com/full/3124572.jpg');
  background-size: cover;
  background-position: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.info-overlay {
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 20px;
  text-align: center;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-around; /* Space items vertically */
}

.info-overlay h3 {
  margin-bottom: 15px;
  color: white;
}

.info-overlay p {
  font-size: 16px;
  margin: 70px 0;
}

.contact-form {
  flex: 1;
  padding: 40px;
  background-color: #f9f9f9;
}

.contact-form h3 {
  margin-bottom: 20px;
  font-size: 24px;
  color: black;
}

.contact-form label {
  display: block;
  margin-bottom: 15px;
  font-size: 14px;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px 0;
  border: none;
  border-bottom: 2px solid #ccc;
  outline: none;
  font-size: 14px;
  background-color: transparent;
  transition: border-color 0.3s ease;
}

.contact-form input:focus,
.contact-form textarea:focus {
  border-bottom: 2px solid #007bff; /* Change color on focus */
}


.submit-button {
  background-color: #000036;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #0056b3;
}
@media (max-width: 768px) {
  .contact-us-container{
    flex-direction: column;
  }
}