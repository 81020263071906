@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Playfair+Display:wght@700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: #274135; /* Green */
  --secondary: #FF9933; /* Orange */
  --light: #F6FFF2; /* Light Background */
  --dark: #4A54A4; /* Dark Background */
}

body, .navbar {
  margin: 0;
  padding: 0;
}

/* Add this block */
.top-bar h2 {
  color: #000000; /* Black color */
}

.btn {
  font-family: 'Roboto', sans-serif;
  letter-spacing: 1px;
  font-weight: 700;
  transition: .5s;
  border: none;
  border-radius: 5px; /* Rounded edges */
}

.btn-primary {
  background-color: var(--primary);
  color: #FFFFFF;
}

.btn-primary:hover {
  background-color: var(--secondary);
  color: #FFFFFF;
}

.btn-square {
  width: 40px; /* Slightly larger */
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.btn-lg-square {
  width: 50px;
  height: 50px;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 30px;
  bottom: 0;
  border-radius: 50%;
  z-index: 99;
  background-color: var(--primary);
  color: #FFFFFF;
  font-size: 24px;
  text-align: center;
  line-height: 50px;
}

.back-to-top:hover {
  background-color: var(--secondary);
  color: #FFFFFF;
}

/* Navbar */
.navbar-dark .navbar-nav .nav-link {
  padding: 20px 20px; /* Increase padding for better spacing */
  font-size: 18px; /* Larger text */
  font-weight: 700; /* Bold text */
  color: #FFFFFF;
  text-transform: uppercase;
  transition: background-color 0.5s, color 0.5s;
}

.navbar {
  background-color: var(--primary) !important; /* Force green background */
  margin-bottom: 0; /* Ensures no extra space below the navbar */
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link.active {
  background: var(--secondary);
  color: #FFFFFF;
  border-radius: 5px; /* Rounded edges for active state */
}

.sticky-top.navbar-dark .navbar-nav .nav-link {
  padding: 15px 15px; /* Adjust for smaller screens */
}

@media (max-width: 991.98px) {
  .navbar-dark .navbar-nav .nav-link {
    padding: 15px 10px; /* Adjust spacing for tablets */
  }
}

@media (max-width: 576px) {
  .navbar-dark .navbar-nav .nav-link {
    font-size: 14px; /* Smaller font size for mobile */
    padding: 10px;
  }

  .btn-square {
    width: 36px;
    height: 36px;
  }

  .navbar-brand img {
    max-width: 150px; /* Reduce the logo size for smaller screens */
    height: auto;
  }

  .top-bar .col-lg-6 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .top-bar .navbar-brand {
    margin-left: auto;
    margin-right: 0;
  }

  .top-bar .social-icons {
    margin-left: 0;
    margin-right: auto;
  }
}

.navbar-brand img {
  max-width: 300px; /* Increase this value to make the logo larger */
  height: auto;
  padding: 5px; /* Adjust padding */
}

.navbar-dark .navbar-brand {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Top Bar Adjustments */
.top-bar {
  padding-top: 20px; /* Added unit */
  padding-bottom: 30px; /* Added unit */
  position: relative;
  z-index: 1020; /* Ensure it's just below the navbar */
}

.top-bar img.full-width-logo {
  max-width: 300px; /* Adjust logo size */
  height: auto;
}

/* Fixed Navbar */
.fixed-navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1030; /* Ensure it's above other content */
}

@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 111%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 111%;
  }

  #portfolio {
    width: 110%;
  }
}

/* Add this to your App.css or a separate CSS file */

.fade-enter {
  opacity: 0;
  transform: translateY(20px); /* Move down when entering */
}

.fade-enter-active {
  opacity: 1;
  transform: translateY(0); /* Move to original position */
  transition: opacity 300ms ease, transform 300ms ease; /* Adjust timing */
}

.fade-exit {
  opacity: 1;
  transform: translateY(0); /* Stay in place when exiting */
}

.fade-exit-active {
  opacity: 0;
  transform: translateY(-20px); /* Move up when exiting */
  transition: opacity 300ms ease, transform 300ms ease; /* Adjust timing */
}

/* components/Loader/Loader.css */
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full screen height */
  background-color: #f7f7f7; /* Light background color */
}

.plant {
  position: relative;
  width: 20px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: grow 2s infinite;
}

.stem {
  width: 4px;
  height: 50px;
  background-color: #228B22; /* Green stem color */
  border-radius: 4px;
  animation: stem-grow 2s ease-in-out infinite;
}

.leaf {
  width: 20px;
  height: 10px;
  background-color: #32CD32; /* Lighter green for the leaf */
  border-radius: 5px;
  position: absolute;
  top: -15px;
  animation: leaf-move 2s infinite alternate;
}

/* Growing animation for the plant */
@keyframes grow {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

/* Animation for stem growing */
@keyframes stem-grow {
  0% {
    height: 50px;
  }
  50% {
    height: 80px; /* Expanding the stem */
  }
  100% {
    height: 50px; /* Shrinking back */
  }
}

/* Animation for leaf moving */
@keyframes leaf-move {
  0% {
    top: -15px;
    transform: rotate(0deg);
  }
  100% {
    top: -30px;
    transform: rotate(45deg); /* Waving effect */
  }
}

.main {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.slider-container {
  position: relative;
  height: 100%;
  background-color: #004d00;
}

.slide {
  position: relative;
}

.image {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4); /* Adjust opacity as needed */
  z-index: 1;
}

.image-data {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: white;
  text-align: left;
  z-index: 2;
  padding: 20px;
  font-family: 'Poppins', sans-serif;
  width: 100%;
  max-width: 1200px;
}

.left-content {
  flex: 1 1;
  text-align: left;
}

.right-content {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 5%; /* Adjust the space as needed */
}

.spacer {
  flex: 0 0 5%; /* Adjust space as needed */
}

.welcome-message h2 {
  margin: 0.5rem 0;
}

.text {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

h1 {
  font-size: 5rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

h3 {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

p {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
}

.button {
  background-color: #006600;
  color: white;
  padding: 1.5rem 3rem;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1.8rem;
  font-weight: 700;
}

.slick-prev,
.slick-next {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  transition: background-color 0.3s;
}

.slick-prev::before,
.slick-next::before {
  color: #5e9b50;
  font-size: 25px;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.slick-prev {
  left: 15px;
}

.slick-next {
  right: 15px;
}

.slick-prev:hover,
.slick-next:hover {
  background-color: rgba(255, 255, 255, 1);
}

.slick-dots li button:before {
  color: white;
  font-size: 12px;
}

.slick-dots li.slick-active button:before {
  color: #5e9b50;
}

@media (max-width: 1200px) {
  .image-data {
    flex-direction: column;
    align-items: flex-start;
  }

  .right-content {
    margin-left: 0;
  }

  .spacer {
    display: none;
  }

  .left-content,
  .right-content {
    width: 100%;
    margin-bottom: 2rem;
  }
}

@media (max-width: 768px) {
  .text {
    font-size: 2rem;
  }

  h1 {
    font-size: 3rem;
  }

  h3 {
    font-size: 2.5rem;
  }

  p {
    font-size: 1.5rem;
  }

  .button {
    padding: 1rem 2rem;
    font-size: 1.5rem;
  }

  .image-data {
    width: 90%;
    align-items: flex-start;
  }
}

@media (max-width: 480px) {
  .text {
    font-size: 1.8rem;
  }

  h1 {
    font-size: 2.5rem;
  }

  h3 {
    font-size: 2rem;
  }

  p {
    font-size: 1.2rem;
  }

  .button {
    padding: 0.8rem 1.5rem;
    font-size: 1.2rem;
  }

  .image-data {
    width: 95%;
  }
}

@media (max-height: 400px) and (orientation: landscape) {
  .main {
    height: auto;
  }

  .image {
    height: 60vh;
  }

  .image-data {
    top: 40%;
    transform: translateY(-40%);
  }

  .text,
  h1,
  h3,
  p,
  .button {
    font-size: 1.5rem;
  }

  .button {
    padding: 1rem 2rem;
  }
}

/* Features Section */
#features {
  background: linear-gradient(to left, #274135, #5e9b50); /* Green gradient background */
  position: relative;
  overflow: hidden;
  padding: 80px 0;
  color: white; /* Text color */
}

#features::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
  opacity: 0.1;
}

/* Plant Stem */
.plant {
  position: absolute;
  bottom: 0;
  width: 2px;
  height: 100px;
  background: green;
  z-index: 1;
}

.plant:before, .plant:after {
  content: '';
  position: absolute;
  bottom: 50%;
  width: 20px;
  height: 20px;
  background: green;
  border-radius: 50%;
  transform: rotate(45deg);
  z-index: -1;
}

.plant:before {
  left: -10px;
}

.plant:after {
  right: -10px;
}

/* Positioning individual plants */
.plant1 {
  left: 10%;
}

.plant2 {
  left: 30%;
  height: 120px;
}

.plant3 {
  left: 50%;
  height: 90px;
}

.plant4 {
  left: 70%;
  height: 110px;
}

.plant5 {
  left: 90%;
  height: 100px;
}

.section-title {
  margin-bottom: 40px;
  position: relative;
  z-index: 2;
}

.section-title h3 {
  color: white !important; /* Title color */
}

.carousel-container {
  width: 100%;
  overflow: hidden;
  position: relative;
  z-index: 2;
}

.carousel {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  cursor: grab;
  grid-gap: 20px;
  gap: 20px; /* Add space between cards */
}

.carousel::-webkit-scrollbar {
  display: none; /* Hide scrollbar */
}

.carousel:active {
  cursor: grabbing;
}

.feature-card {
  flex: 0 0 48%; /* Each card takes 48% of the width to fit two cards */
  margin: 0; /* Remove extra margins */
  position: relative;
  box-sizing: border-box;
}

.feature-content {
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease;
  color: black; /* Text color */
}

.feature-content:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.feature-image {
  width: 100%;
  height: 200px; /* Adjust height as needed */
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 20px;
}

.feature-content h3 {
  font-size: 22px;
  margin-bottom: 20px;
  text-align: center;
  color: black; /* Title color */
}

.feature-content p {
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: black; /* Text color */
}

.carousel-dots {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  position: relative;
  z-index: 2;
}

.dot {
  height: 10px;
  width: 10px;
  margin: 0 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.dot.active {
  background-color: #717171;
}

/* AOS Animations */
[data-aos="fade-up"] {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.8s ease-in-out, transform 0.8s ease-in-out;
}

[data-aos="fade-up"].aos-animate {
  opacity: 1;
  transform: translateY(0);
}

/* Adjustments for mobile responsiveness */
@media (max-width: 768px) {
  .feature-card {
    flex: 0 0 100%; /* Show one card at a time on smaller screens */
  }

  [data-aos="fade-up"] {
    opacity: 1;
    transform: none;
  }
}

.home1-section {
  position: relative;
  height: 60vh; /* Adjust this value to control the video height */
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.content-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(5, 28, 44, 0.7); /* Semi-transparent overlay covering the video */
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

h1 {
  font-size: 3rem;
  color: white;
  margin: 0;
}

h2 {
  font-size: 2rem;
  color: white;
  margin: 10px 0 0 0;
}

:root {
  --primary-color: #274135;
  --secondary-color: #5e9b50;
  --text-color: #4E4E4E;
  --bg-color: #F5F5F5;
  --light-bg-color: #E8F5E9;
  --dark-text-color: #3E2723;
  --light-text-color: #6D4C41;
}

body {
  font-family: 'Arial', sans-serif;
  color: var(--text-color);
  background-color: var(--bg-color);
}

.services-container-fluid {
  padding: 0 15px;
}

.bg-primary {
  background-color: var(--primary-color); /* A nice green color for primary sections */
}

.text-primary {
  color: var(--primary-color); /* Matching green for text */
}

.btn-primary {
  background-color: var(--primary-color);
  border: none;
  color: #FFFFFF;
}

.btn-secondary {
  background-color: var(--secondary-color);
  border: none;
  color: #FFFFFF;
}

.service-item {
  border: 1px solid #CCCCCC;
  border-radius: 10px;
  transition: transform 0.3s;
}

.service-item:hover {
  transform: scale(1.05);
  background-color: var(--light-bg-color);
}

h1, h4, p {
  margin: 0 0 15px;
}

.text-uppercase {
  text-transform: uppercase;
}

.display-1 {
  font-size: 3.5rem;
}

.display-5 {
  font-size: 2.5rem;
}

.py-5 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.mb-3 {
  margin-bottom: 1rem;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

.btn {
  padding: 0.75rem 1.5rem;
  border-radius: 5px;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.bg-hero {
  background: url('/img/2.jpg') no-repeat center center;
  background-size: cover;
  background-color: var(--primary-color); /* Fallback color if the image doesn't load */
}

.service-item .display-1 {
  color: var(--primary-color); /* Icon color */
}

.service-item h4 {
  color: var(--dark-text-color); /* Dark brown color */
}

.service-item p {
  color: var(--light-text-color); /* Light brown color */
}

.services-container {
  max-width: 1200px;
  margin: auto;
}

/* Icon Colors */
.icon-1 {
  color: var(--primary-color); /* Capacity Building - Green */
}

.icon-2 {
  color: #FFA726; /* Quality Inputs - Orange */
}

.icon-3 {
  color: #26C6DA; /* Agro-Processing - Cyan */
}

/* Testimonials Section */
#testimonials {
  padding: 100px 0;
  background:  white;
}
.text-center h2{
  color: black !important;
}
#testimonials i {
  color: #e6e6e6;
  font-size: 32px;
  margin-bottom: 20px;
}

.testimonial {
  position: relative;
  padding: 20px;
}

.testimonial-image {
  float: left;
  margin-right: 15px;
}

.testimonial-image,
.testimonial-image img {
  display: block;
  width: 64px;
  height: 64px;
  border-radius: 50%;
}

.testimonial-content {
  position: relative;
  overflow: hidden;
}

.testimonial-content p {
  margin-bottom: 0;
  font-size: 14px;
  font-style: italic;
  color: black; /* Ensure text is black */
}

.testimonial-meta {
  margin-top: 10px;
  font-size: 15px;
  font-weight: 600;
  color: black; /* Ensure text is black */
}

.section-title h2 {
  color: black; /* Ensure title is black */
}

/* Universal Padding and Margin Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --box-shadow: 9px 9px 30px rgba(0, 0, 0, 0.3);
  --hover-shadow: 0 9px 15px rgba(0, 0, 0, 0.1);
  --wave-duration: 2s;
}

.categories {
  padding: 70px 0;
}

.box {
  width: 100%;
  height: 300px; /* Ensure the cards have the same height */
  margin-bottom: 30px;
  position: relative;
  padding: 3px;
  overflow: hidden;
  border: 0 solid #000;
  box-shadow: var(--box-shadow);
  transition: all 0.3s ease-in-out;
}

.box img {
  width: 100%;
  height: 100%; /* Ensure the images fill the card */
  opacity: 0.9;
  transition: opacity 0.35s, transform 0.35s;
  animation: wave var(--wave-duration) infinite ease-in-out;
}

.details {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  opacity: 0;
  transition: opacity 0.35s;
}

.box:hover .details {
  opacity: 1;
}

.view-btn {
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.box:hover img {
  opacity: 0.6;
  transform: translate3d(0, 0, 0);
}

@keyframes wave {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
}

.close {
  position: absolute;
  top: 20px;
  right: 30px;
  color: #fff;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
}

.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  animation: zoom 0.6s;
  position: relative;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #fff;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 5px;
  border-radius: 50%;
  box-shadow: var(--box-shadow);
}

@keyframes zoom {
  from {transform: scale(0)} 
  to {transform: scale(1)}
}

@media screen and (max-width: 700px) {
  .modal-content {
    width: 100%;
  }
}


.contact-us-container {
  display: flex;
  max-width: 1000px;
  margin: auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-top: 80px;
  margin-bottom: 50px;
}

.contact-info {
  flex: 1 1;
  position: relative;
}
.contact-form input::placeholder,
.contact-form textarea::placeholder {
  color: #aaa; /* Lighter color for the placeholder text */
  opacity: 1;  /* Ensure full opacity */
}


.background-image {
  background-image: url('https://wallpaperaccess.com/full/3124572.jpg');
  background-size: cover;
  background-position: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.info-overlay {
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 20px;
  text-align: center;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-around; /* Space items vertically */
}

.info-overlay h3 {
  margin-bottom: 15px;
  color: white;
}

.info-overlay p {
  font-size: 16px;
  margin: 70px 0;
}

.contact-form {
  flex: 1 1;
  padding: 40px;
  background-color: #f9f9f9;
}

.contact-form h3 {
  margin-bottom: 20px;
  font-size: 24px;
  color: black;
}

.contact-form label {
  display: block;
  margin-bottom: 15px;
  font-size: 14px;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px 0;
  border: none;
  border-bottom: 2px solid #ccc;
  outline: none;
  font-size: 14px;
  background-color: transparent;
  transition: border-color 0.3s ease;
}

.contact-form input:focus,
.contact-form textarea:focus {
  border-bottom: 2px solid #007bff; /* Change color on focus */
}


.submit-button {
  background-color: #000036;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #0056b3;
}
@media (max-width: 768px) {
  .contact-us-container{
    flex-direction: column;
  }
}
#faq {
  font-family: "Arial", sans-serif;
  padding: 20px;
  max-width: 800px;
  margin-top: 100px !important;
  margin: auto;
  background: linear-gradient(
      135deg,
      rgba(240, 240, 240, 0.9),
      rgba(232, 232, 232, 0.9)
    ),
    url("/img/S6.png") no-repeat center center;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

#faq h2 {
  text-align: center;
  margin-bottom: 30px;
  padding-bottom: 10px;
  border-bottom: 3px solid #3498db; /* Blue accent */
  font-size: 2.5rem;
  color: #2c3e50;
  font-weight: bold;
  text-transform: uppercase;
}

.faq-category {
  margin-bottom: 40px;
}

.faq-category-title {
  font-size: 2rem;
  margin-bottom: 20px;
  font-weight: bold;
  color: #2c3e50;
  border-left: 5px solid #3498db; /* Accent color */
  padding-left: 10px;
  text-transform: uppercase;
}

.faq-item {
  margin-bottom: 15px;
  padding: 15px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease;
  border-left: 3px solid transparent;
}

.faq-item:hover {
  transform: translateY(-5px);
  border-left: 3px solid #3498db;
  background-color: #f9f9f9;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 1.3rem;
  font-weight: bold;
  color: #34495e;
  transition: color 0.3s ease;
}

.faq-question:hover {
  color: #3498db;
}

.faq-answer {
  margin-top: 10px;
  padding-left: 20px;
  font-size: 1.2rem;
  line-height: 1.6;
  color: #7f8c8d;
  transition: all 0.3s ease;
}

.faq-item.active .faq-answer {
  display: block; /* Show on active state */
}
.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease, padding 0.3s ease;
}

.faq-item .faq-answer {
  max-height: 500px; /* Adjust based on content */
  padding-top: 10px;
}

.faq-icon {
  font-size: 1.5rem;
  color: #3498db;
  transition: transform 0.3s ease;
}

.faq-item.active .faq-icon {
  transform: rotate(45deg); /* Rotates icon on active */
}

/* Add subtle animations */
.faq-item {
  animation: fadeInUp 0.5s ease both;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Add responsive design */
@media (max-width: 768px) {
  #faq {
    padding: 15px;
  }

  #faq h2 {
    font-size: 2rem;
  }

  .faq-category-title {
    font-size: 1.5rem;
  }

  .faq-question {
    font-size: 1.1rem;
  }

  .faq-answer {
    font-size: 1rem;
  }
}

/* General Styles */
.all-title-box {
  background: url('/img/cattle.jpg') no-repeat center center;
  background-size: cover;
  padding: 50px 0;
  text-align: center;
  color: #fff;
  font-family: 'Roboto', sans-serif;
}

.all-title-box h2 {
  font-size: 36px;
  font-weight: 700;
}

.breadcrumb {
  padding: 0;
  list-style: none;
  background: none;
  margin-bottom: 0;
  display: inline-block; /* Ensure breadcrumb is inline */
}

.breadcrumb-item {
  display: inline; /* Ensure items are inline */
  color: #fff; /* Ensure visibility */
}

.breadcrumb-item a {
  color: #fff; /* Ensure links are visible */
}

.breadcrumb-item.active {
  color: #e0e0e0; /* Slightly different color for active item */
}

.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  padding: 0 5px;
  color: #fff; /* Ensure separator is visible */
}

/* About Section */
.about-box-main {
  display: flex;
  align-items: center;
  padding: 50px 0;
  background: #ffffff;
}

.about-box-main .banner-frame {
  flex: 1 1;
  max-height: 400px;
  overflow: hidden;
}

.banner-frame img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about-box-main .noo-sh-title-top {
  flex: 1 1;
  font-family: 'Open Sans', sans-serif;
  font-size: 36px;
  font-weight: 700;
  color: #333;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-bottom: 3px solid #5cb85c;
  display: inline-block;
  padding-bottom: 5px;
}

.about-box-main p {
  flex: 1 1;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  line-height: 1.6;
  color: #666;
  margin-bottom: 20px;
  text-align: justify;
}

.about-box-main .btn {
  background: #5cb85c;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 30px;
  transition: all 0.3s;
}

.about-box-main .btn:hover {
  background: #4cae4c;
  color: #ffffff;
}

/* Service Section */
.service-block-inner {
  text-align: center;
  padding: 20px;
  border: 1px solid #f1f1f1;
  background: #f8f8f8;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.service-block-inner h3 {
  font-size: 22px;
  font-weight: 700;
  margin: 10px 0;
}

.service-block-inner p {
  font-size: 14px;
  line-height: 24px;
  color: #666;
}

.icon-color {
  color: #5e9b50;
  margin-bottom: 10px;
}

/* Team Section */
.hover-team {
  margin-bottom: 30px;
}

.our-team {
  position: relative;
  overflow: hidden;
  text-align: center;
  background: #ffffff;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.our-team img {
  width: 100%;
  height: auto;
}

.team-content {
  padding: 15px 10px;
}

.team-content h3 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 5px;
}

.team-content .post {
  display: block;
  font-size: 14px;
  color: #5e9b50;
  margin-bottom: 10px;
}

.social {
  padding: 0;
  margin: 0;
  list-style: none;
  display: inline-flex;
  justify-content: center;
}

.social li {
  margin: 0 5px;
}

.social li a {
  display: block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  background: #5e9b50;
  color: #ffffff;
  border-radius: 50%;
  text-align: center;
  transition: all 0.3s;
}

.social li a:hover {
  background: #5e9b50;
}

/* Instagram Feed */
.instagram-box {
  padding: 50px 0;
  background: #f8f8f8;
}

.instagram-box .ins-inner-box {
  position: relative;
  overflow: hidden;
}

.instagram-box img {
  width: 100%;
  height: auto;
  transition: all 0.3s;
}

.instagram-box .hov-in {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.3s;
}

.instagram-box:hover .hov-in {
  opacity: 1;
}

.instagram-box:hover img {
  transform: scale(1.1);
}

.instagram-box .hov-in a {
  display: inline-block;
  background: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  padding: 10px;
  border-radius: 50%;
}

/* Responsive Design */
@media (max-width: 768px) {
  .all-title-box {
    padding: 30px 0;
  }
  .about-box-main {
    flex-direction: column;
  }
  .service-block-inner {
    margin-bottom: 20px;
  }
  .social li a {
    width: 25px;
    height: 25px;
    line-height: 25px;
  }
}
/* Responsive Design */
@media (max-width: 768px) {
  .all-title-box {
    padding: 30px 0;
  }
  .about-box-main {
    flex-direction: column;
  }
  .about-box-main .banner-frame {
    margin-bottom: 20px; /* Adding space between image and text */
  }
  .breadcrumb {
    font-size: 14px; /* Smaller font for breadcrumbs on small screens */
  }
  .service-block-inner {
    margin-bottom: 20px;
  }
  .social li a {
    width: 25px;
    height: 25px;
    line-height: 25px;
  }
}

@media (max-width: 576px) {
  .all-title-box h2 {
    font-size: 28px;
  }
  .about-box-main .noo-sh-title-top {
    font-size: 24px;
  }
  .about-box-main p {
    font-size: 16px;
  }
  .service-block-inner h3 {
    font-size: 18px;
  }
  .service-block-inner p {
    font-size: 12px;
  }
}

/* Ensure the entire background is consistent */
body, html {
  background-color: #f6f6f6;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: 'Roboto', sans-serif; /* Applying Roboto font */
}

/* Values Section Container */
.values-section {
  display: flex;
  flex-direction: column;
  grid-gap: 40px;
  gap: 40px;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #f6f6f6; /* Consistent background color */
  text-align: center;
}

.values-header h1 {
  font-family: "Raleway", sans-serif;
  font-size: 36px;
  color: #333;
  margin-bottom: 10px;
}

.values-header p {
  font-size: 18px;
  color: #777;
  margin-bottom: 20px;
}

.values-content {
  display: flex;
  align-items: stretch; /* Ensure the image matches the height of the cards */
  grid-gap: 40px;
  gap: 40px;
  flex-direction: row; /* Image and cards side by side */
}

/* Image Styling */
.values-image {
  flex: 1 1; /* Allows image to take equal space as container */
}

.values-image img {
  width: 100%;
  height: 100%; /* Match image height to container */
  object-fit: cover; /* Ensures the image covers the container without distortion */
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* Values Container */
.values-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns */
  grid-gap: 20px; /* Space between the cards */
  max-width: 700px;
  flex: 1 1; /* Take up equal space with image */
}

/* Individual Cards */
.value-card {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content horizontally */
  justify-content: center; /* Center content vertically */
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, background-color 0.3s;
  overflow: hidden;
  text-align: center;
}

.value-card:hover {
  transform: translateY(-10px);
  background-color: #e6ffe6;
}

.value-card-icon {
  font-size: 3em;
  margin-bottom: 10px;
  color: #2ecc71; /* Green color for icons */
}

.value-card-content h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #333;
}

.value-card-content p {
  font-size: 1em;
  color: #777;
}

.description-hover {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  background-color: rgba(0, 128, 0, 0.7); /* Greenish background */
  color: #fff;
  opacity: 0;
  transform: translateY(100%);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.value-card:hover .description-hover {
  opacity: 1;
  transform: translateY(0);
}

/* Responsive Design */
@media (max-width: 768px) {
  .values-content {
    flex-direction: column; /* Stack image and cards vertically */
    align-items: center;
  }

  .values-image {
    width: 100%;
    height: auto;
  }

  .values-container {
    grid-template-columns: 1fr; /* Single column on smaller screens */
    grid-gap: 20px;
    gap: 20px; /* Ensure proper spacing in single-column view */
  }

  .value-card {
    width: 100%;
  }
}

/* Import Google Fonts */

/* General styles */
body {
  font-family: 'Roboto', sans-serif;
}

.products-title {
  font-family: 'Playfair Display', serif;
  text-align: center;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 40px;
  color: #333;
}

.products-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-gap: 20px;
  gap: 20px;
}

.product-card {
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  transition: all 0.3s ease;
  width: 300px;
}

.product-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.product-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 20px;
}

.product-name {
  font-size: 22px;
  margin-bottom: 10px;
  font-family: 'Playfair Display', serif;
}

.product-description {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
  font-family: 'Roboto', sans-serif;
}

.product-price {
  font-size: 18px;
  font-weight: 700;
  color: #2E8B57;
}

.footer {
  background: #274135; /* Dark green background */
  color: white;
  padding: 40px 20px;
}

.footer .container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.footer-content {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 20px;
  gap: 20px;
  width: 100%;
}

.footer-section {
  flex: 1 1;
  min-width: 200px; /* Ensures sections don't shrink too much */
}

.footer-section h4 {
  font-size: 18px;
  margin-bottom: 10px;
}

.footer-section p, .footer-section ul {
  margin: 0 0 10px;
  font-size: 14px;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 5px;
}

.footer-section ul li a {
  color: white;
  text-decoration: none;
}

.footer-section ul li a:hover {
  text-decoration: underline;
}

.social-icons {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}

.social-icons a {
  color: white;
  font-size: 18px;
  display: inline-block;
}

.footer-bottom {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding-top: 10px;
  width: 100%; /* Ensure it takes full width */
}

