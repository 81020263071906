:root {
  --primary: #274135; /* Green */
  --secondary: #FF9933; /* Orange */
  --light: #F6FFF2; /* Light Background */
  --dark: #4A54A4; /* Dark Background */
}

body, .navbar {
  margin: 0;
  padding: 0;
}

/* Add this block */
.top-bar h2 {
  color: #000000; /* Black color */
}

.btn {
  font-family: 'Roboto', sans-serif;
  letter-spacing: 1px;
  font-weight: 700;
  transition: .5s;
  border: none;
  border-radius: 5px; /* Rounded edges */
}

.btn-primary {
  background-color: var(--primary);
  color: #FFFFFF;
}

.btn-primary:hover {
  background-color: var(--secondary);
  color: #FFFFFF;
}

.btn-square {
  width: 40px; /* Slightly larger */
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.btn-lg-square {
  width: 50px;
  height: 50px;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 30px;
  bottom: 0;
  border-radius: 50%;
  z-index: 99;
  background-color: var(--primary);
  color: #FFFFFF;
  font-size: 24px;
  text-align: center;
  line-height: 50px;
}

.back-to-top:hover {
  background-color: var(--secondary);
  color: #FFFFFF;
}

/* Navbar */
.navbar-dark .navbar-nav .nav-link {
  padding: 20px 20px; /* Increase padding for better spacing */
  font-size: 18px; /* Larger text */
  font-weight: 700; /* Bold text */
  color: #FFFFFF;
  text-transform: uppercase;
  transition: background-color 0.5s, color 0.5s;
}

.navbar {
  background-color: var(--primary) !important; /* Force green background */
  margin-bottom: 0; /* Ensures no extra space below the navbar */
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link.active {
  background: var(--secondary);
  color: #FFFFFF;
  border-radius: 5px; /* Rounded edges for active state */
}

.sticky-top.navbar-dark .navbar-nav .nav-link {
  padding: 15px 15px; /* Adjust for smaller screens */
}

@media (max-width: 991.98px) {
  .navbar-dark .navbar-nav .nav-link {
    padding: 15px 10px; /* Adjust spacing for tablets */
  }
}

@media (max-width: 576px) {
  .navbar-dark .navbar-nav .nav-link {
    font-size: 14px; /* Smaller font size for mobile */
    padding: 10px;
  }

  .btn-square {
    width: 36px;
    height: 36px;
  }

  .navbar-brand img {
    max-width: 150px; /* Reduce the logo size for smaller screens */
    height: auto;
  }

  .top-bar .col-lg-6 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .top-bar .navbar-brand {
    margin-left: auto;
    margin-right: 0;
  }

  .top-bar .social-icons {
    margin-left: 0;
    margin-right: auto;
  }
}

.navbar-brand img {
  max-width: 300px; /* Increase this value to make the logo larger */
  height: auto;
  padding: 5px; /* Adjust padding */
}

.navbar-dark .navbar-brand {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Top Bar Adjustments */
.top-bar {
  padding-top: 20px; /* Added unit */
  padding-bottom: 30px; /* Added unit */
  position: relative;
  z-index: 1020; /* Ensure it's just below the navbar */
}

.top-bar img.full-width-logo {
  max-width: 300px; /* Adjust logo size */
  height: auto;
}

/* Fixed Navbar */
.fixed-navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1030; /* Ensure it's above other content */
}
