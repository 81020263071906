/* Features Section */
#features {
  background: linear-gradient(to left, #274135, #5e9b50); /* Green gradient background */
  position: relative;
  overflow: hidden;
  padding: 80px 0;
  color: white; /* Text color */
}

#features::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
  opacity: 0.1;
}

/* Plant Stem */
.plant {
  position: absolute;
  bottom: 0;
  width: 2px;
  height: 100px;
  background: green;
  z-index: 1;
}

.plant:before, .plant:after {
  content: '';
  position: absolute;
  bottom: 50%;
  width: 20px;
  height: 20px;
  background: green;
  border-radius: 50%;
  transform: rotate(45deg);
  z-index: -1;
}

.plant:before {
  left: -10px;
}

.plant:after {
  right: -10px;
}

/* Positioning individual plants */
.plant1 {
  left: 10%;
}

.plant2 {
  left: 30%;
  height: 120px;
}

.plant3 {
  left: 50%;
  height: 90px;
}

.plant4 {
  left: 70%;
  height: 110px;
}

.plant5 {
  left: 90%;
  height: 100px;
}

.section-title {
  margin-bottom: 40px;
  position: relative;
  z-index: 2;
}

.section-title h3 {
  color: white !important; /* Title color */
}

.carousel-container {
  width: 100%;
  overflow: hidden;
  position: relative;
  z-index: 2;
}

.carousel {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  cursor: grab;
  gap: 20px; /* Add space between cards */
}

.carousel::-webkit-scrollbar {
  display: none; /* Hide scrollbar */
}

.carousel:active {
  cursor: grabbing;
}

.feature-card {
  flex: 0 0 48%; /* Each card takes 48% of the width to fit two cards */
  margin: 0; /* Remove extra margins */
  position: relative;
  box-sizing: border-box;
}

.feature-content {
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease;
  color: black; /* Text color */
}

.feature-content:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.feature-image {
  width: 100%;
  height: 200px; /* Adjust height as needed */
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 20px;
}

.feature-content h3 {
  font-size: 22px;
  margin-bottom: 20px;
  text-align: center;
  color: black; /* Title color */
}

.feature-content p {
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: black; /* Text color */
}

.carousel-dots {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  position: relative;
  z-index: 2;
}

.dot {
  height: 10px;
  width: 10px;
  margin: 0 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.dot.active {
  background-color: #717171;
}

/* AOS Animations */
[data-aos="fade-up"] {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.8s ease-in-out, transform 0.8s ease-in-out;
}

[data-aos="fade-up"].aos-animate {
  opacity: 1;
  transform: translateY(0);
}

/* Adjustments for mobile responsiveness */
@media (max-width: 768px) {
  .feature-card {
    flex: 0 0 100%; /* Show one card at a time on smaller screens */
  }

  [data-aos="fade-up"] {
    opacity: 1;
    transform: none;
  }
}
