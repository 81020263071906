/* components/Loader/Loader.css */
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full screen height */
  background-color: #f7f7f7; /* Light background color */
}

.plant {
  position: relative;
  width: 20px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: grow 2s infinite;
}

.stem {
  width: 4px;
  height: 50px;
  background-color: #228B22; /* Green stem color */
  border-radius: 4px;
  animation: stem-grow 2s ease-in-out infinite;
}

.leaf {
  width: 20px;
  height: 10px;
  background-color: #32CD32; /* Lighter green for the leaf */
  border-radius: 5px;
  position: absolute;
  top: -15px;
  animation: leaf-move 2s infinite alternate;
}

/* Growing animation for the plant */
@keyframes grow {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

/* Animation for stem growing */
@keyframes stem-grow {
  0% {
    height: 50px;
  }
  50% {
    height: 80px; /* Expanding the stem */
  }
  100% {
    height: 50px; /* Shrinking back */
  }
}

/* Animation for leaf moving */
@keyframes leaf-move {
  0% {
    top: -15px;
    transform: rotate(0deg);
  }
  100% {
    top: -30px;
    transform: rotate(45deg); /* Waving effect */
  }
}
