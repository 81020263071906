.home1-section {
  position: relative;
  height: 60vh; /* Adjust this value to control the video height */
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.content-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(5, 28, 44, 0.7); /* Semi-transparent overlay covering the video */
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

h1 {
  font-size: 3rem;
  color: white;
  margin: 0;
}

h2 {
  font-size: 2rem;
  color: white;
  margin: 10px 0 0 0;
}
