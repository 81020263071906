:root {
  --primary-color: #274135;
  --secondary-color: #5e9b50;
  --text-color: #4E4E4E;
  --bg-color: #F5F5F5;
  --light-bg-color: #E8F5E9;
  --dark-text-color: #3E2723;
  --light-text-color: #6D4C41;
}

body {
  font-family: 'Arial', sans-serif;
  color: var(--text-color);
  background-color: var(--bg-color);
}

.services-container-fluid {
  padding: 0 15px;
}

.bg-primary {
  background-color: var(--primary-color); /* A nice green color for primary sections */
}

.text-primary {
  color: var(--primary-color); /* Matching green for text */
}

.btn-primary {
  background-color: var(--primary-color);
  border: none;
  color: #FFFFFF;
}

.btn-secondary {
  background-color: var(--secondary-color);
  border: none;
  color: #FFFFFF;
}

.service-item {
  border: 1px solid #CCCCCC;
  border-radius: 10px;
  transition: transform 0.3s;
}

.service-item:hover {
  transform: scale(1.05);
  background-color: var(--light-bg-color);
}

h1, h4, p {
  margin: 0 0 15px;
}

.text-uppercase {
  text-transform: uppercase;
}

.display-1 {
  font-size: 3.5rem;
}

.display-5 {
  font-size: 2.5rem;
}

.py-5 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.mb-3 {
  margin-bottom: 1rem;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

.btn {
  padding: 0.75rem 1.5rem;
  border-radius: 5px;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.bg-hero {
  background: url('/img/2.jpg') no-repeat center center;
  background-size: cover;
  background-color: var(--primary-color); /* Fallback color if the image doesn't load */
}

.service-item .display-1 {
  color: var(--primary-color); /* Icon color */
}

.service-item h4 {
  color: var(--dark-text-color); /* Dark brown color */
}

.service-item p {
  color: var(--light-text-color); /* Light brown color */
}

.services-container {
  max-width: 1200px;
  margin: auto;
}

/* Icon Colors */
.icon-1 {
  color: var(--primary-color); /* Capacity Building - Green */
}

.icon-2 {
  color: #FFA726; /* Quality Inputs - Orange */
}

.icon-3 {
  color: #26C6DA; /* Agro-Processing - Cyan */
}
